<template>
  <b-card>
    <b-table
      ref="table"
      :no-provider-sorting="true"
      :items="pollsProvider"
      :fields="tableColumns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      aria-busy="true"
      class="position-relative"
      responsive
      primary-key="id"
      empty-text="No polls found"
      show-empty
    >
      <template
        #cell(actions)="{item}"
      >
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item
            v-if="$can('update','global-PollController')"
            :to="{ name: 'edit-poll', params: {liveId:$route.params.liveId, activityId:$route.params.activityId, id:item.id} }"
          >
            <feather-icon icon="EditIcon" />
            <span> Edit </span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$can('delete','global-PollController')"
            @click="deleteRecord(deleteEndPoint($route.params.liveId),item.id)"
          >
            <feather-icon
              icon="XOctagonIcon"
              class="red"
            />
            <span class="align-middle ml-50 red">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import deletePoll from '@/common/compositions/common/deleteRecord'

export default {
  name: 'PollsTable',
  props: {
    pollsProvider: { type: Function, default: () => [] },
    pagination: { type: Object, default: () => [] },
  },
  data() {
    return {
      tableColumns: [
        { key: 'question' },
        { key: 'valid_from' },
        { key: 'valid_to' },
        { key: 'actions' },
      ],
    }
  },
  setup() {
    const { deleteRecord, table } = deletePoll()
    return { deleteRecord, table }
  },
  methods: {
    deleteEndPoint(liveId) {
      return `${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/poll-session/${liveId}/poll/`
    },
  },
}
</script>
<style lang="scss">
.red {
  color:#f00
}
</style>
