<template>
  <div>
    <b-row
      align-h="end"
      class="mb-1 mb-md-0"
    >
      <b-col
        md="2"
        class="mb-1"
      >
        <b-button
          :to="{ name: 'winners-list', params: {liveId:$route.params.liveId, activityId:$route.params.activityId} }"
          variant="primary"
          class="btn-block"
        >
          <span class="text-nowrap">View Answers</span>
        </b-button>
      </b-col>

      <b-col
        v-if="isLiveInProgress && $can('store','global-PollController')"
        md="2"
        class="mb-1"
      >
        <b-button
          variant="primary"
          class="btn-block"
          @click="addQuestion"
        >
          <span class="text-nowrap">Add Question</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>

    <polls-table
      v-bind="{
        pollsProvider,
        pagination,
        isLiveInProgress
      }"
    />

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import PollsTable from '@/common/components/Polls/PollsTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import paginationData from '@/common/compositions/common/paginationData'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'PollsList',
  components: { PollsTable, Pagination },
  data() {
    return {
      loading: true,
      liveEndDate: '',
      isTherePolls: false,
      firstPollId: null,
    }
  },
  computed: {
    isLiveInProgress() {
      return this.$moment().isBefore(this.liveEndDate)
    },
  },
  setup() {
    const { pagination } = paginationData()
    const { confirmOperation } = handleAlerts()
    return {
      pagination, confirmOperation,
    }
  },
  created() {
    this.getLive()
  },
  methods: {
    getLive() {
      this.$activities.get(`internalops/live/${this.$router.currentRoute.params.activityId}?with-reactions=false`).then(res => {
        this.liveEndDate = res.data.data.session_end
      })
    },
    pollsProvider() {
      return this.$activities.get(`/internalops/poll-session/${this.$route.params.liveId}/poll`).then(res => {
        const polls = res.data.data || []
        this.firstPollId = polls[0]?.id
        this.isTherePolls = !!polls.length
        return polls || []
      }).catch(() => []).finally(() => {
        this.loading = false
      })
    },
    addQuestion() {
      if (this.isTherePolls) {
        this.confirmOperation('You can not add more than one poll to a single live “delete the added one first“').then(() => {
          this.deletePoll(this.firstPollId).then(() => {
            this.$router.push({ name: 'add-poll', params: { liveId: this.$route.params.liveId, activityId: this.$route.params.activityId } })
          })
        })
      } else {
        this.$router.push({ name: 'add-poll', params: { liveId: this.$route.params.liveId, activityId: this.$route.params.activityId } })
      }
    },
    deletePoll(id) {
      return this.$activities.delete(`/internalops/poll-session/${this.$route.params.liveId}/poll/${id}`).then(res => res)
    },
  },
}
</script>
<style lang="scss">

</style>
